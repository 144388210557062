.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white !important;
}

.backgroundImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.backgroundOverlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
