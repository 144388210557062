@import '../../../../styles/customMediaQueries.css';

.root {

  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

}

.accordion {
  /* background-color: #eee; */
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

.closeAccordion {
  background-color: #ccc;
}

/* 
.active, .accordion:hover {
  background-color: #ccc;
} */

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

.panel.show {
  display: block;
  height: 1000px;
  overflow-y: scroll;
}